import React from "react";
import Navigation from "@/components/Navigation/Navigation";
import { Bold, Heading } from "@/components/Typography";
import Head from "next/head";

import s from "./ErrorTemplate.module.scss";

interface Props {
	statusCode: "404" | "500" | string;
	title?: string;
}

export default function ErrorTemplate({ statusCode, title }: Props) {
	const getTitle = () => {
		if (title) return title;

		switch (statusCode) {
			case "404":
				return "Pagina niet gevonden.";
			case "500":
				return "Er is iets fout gegaan op de server.";
			default:
				return "Er is een onbekende fout opgetreden.";
		}
	};

	return (
		<>
			<Head>
				<title>
					{statusCode} - {title}
				</title>
			</Head>
			<Navigation left="logo" right="menu" />

			<main className={s.main}>
				<Heading as="h1" sizeMobile="xxl" sizeDesktop="xl" className={s.error}>
					<Bold className={s.errorCode}>{statusCode}</Bold>
					<span>{getTitle()}</span>
				</Heading>
			</main>
		</>
	);
}
